import React from "react";
import {connect} from "react-redux";
import {CallbackComponent} from "redux-oidc";
import userManager from "../../utils/userManager";
import {withRouter} from "react-router";
import CircularProgress from '@material-ui/core/CircularProgress';


class CallbackPage extends React.Component {

    goToDestination() {
        let redirect_after_login = sessionStorage.getItem('after_login_redirect')
        if (redirect_after_login) {
            redirect_after_login = JSON.parse(redirect_after_login)
            sessionStorage.removeItem('after_login_redirect')
            window.location.replace("/#" + redirect_after_login.pathname + redirect_after_login.search)
        }
        else {
            window.location.replace("/")
        }
    }

    render() {
        const {classes} = this.props
        const goToDestination = this.goToDestination

        return (
            <CallbackComponent
                userManager={userManager}
                successCallback={function () {
                    goToDestination()
                }}
                errorCallback={function (error) {
                    console.error(error)
                    goToDestination()
                }}
            >
                <CircularProgress style={{marginLeft: '50%'}}/>

            </CallbackComponent>
        );
    }
}

export default withRouter(connect()(CallbackPage));
