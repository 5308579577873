import React, {Component} from "react";
import {Route, Switch} from "react-router";
import SupportMain from "./SupportMain";
import SupportContact from "./SupportContact";
import "./support.scss"

export default class Support extends Component {

    render() {
        const {match} = this.props

        return (
            <div className="sa-popup-wrapper">
                <Switch>
                    <Route exact path={`${match.path}/`} component={SupportMain}/>
                    <Route path={`${match.path}/contact`} component={SupportContact}/>
                </Switch>
            </div>)
    }
}