import React, {Component} from 'react';
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import LeftDecor from "../../theme/popup-left-decor.png";
import RightDecor from "../../theme/popup-right-decor.png";

const styles = theme => ({
    dialogContent: {
        marginBottom: 50
    },
    largerContent: {
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 'calc(50% - 95px)',
            height: 191,
            width: 10,
            left: 0,
            background: `url(${LeftDecor}) no-repeat`
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 'calc(50% - 95px)',
            height: 191,
            width: 10,
            right: 0,
            background: `url(${RightDecor}) no-repeat`
        },
    },
    largerDialogContent: {
        minHeight: 155
    }
})

class ConfirmDialog extends Component {
    render() {
        const {classes, closeCancel, closeAgree, title, body, cancelText, agreeText, largerContent} = this.props
        return (
            <Dialog
                open={true}
                onClose={closeCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div className={largerContent ? classes.largerContent : null}>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent className={classes.dialogContent + (largerContent ? " " + classes.largerDialogContent : null)}>{body}</DialogContent>
                    <DialogActions>
                        <Button onClick={closeCancel} color="#a7bfcb">
                            {cancelText}
                        </Button>
                        {agreeText &&
                        <Button onClick={closeAgree} color="primary" variant="contained" autoFocus>
                            {agreeText}
                        </Button>
                        }
                    </DialogActions>
                </div>
            </Dialog>
        );
    }
}

ConfirmDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    closeCancel: PropTypes.func.isRequired,
    closeAgree: PropTypes.func,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    agreeText: PropTypes.string,
    largerContent: PropTypes.bool
}

export default withStyles(styles)(ConfirmDialog)