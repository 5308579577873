import {combineReducers} from 'redux'
import {reducer as oidcReducer} from 'redux-oidc';
import {USER_TYPE, ORG_TYPE} from '../actions/entities'
import {CLEAR_ENTITY, MAKE_REQUEST, REQUEST_FAILED, REQUEST_SUCCESS, UPDATE_STORE} from '../actions/entitiesActions'


function user(state = initialState({}), action) {
    return entityReducer(state, action, USER_TYPE)
}
function org(state = initialState({}), action) {
    return entityReducer(state, action, ORG_TYPE)
}

const initialState = (defaultData => ({
    loading: false,
    saving: false,
    deleting: false,
    error: null,
    data: defaultData
}))

function entityReducer(state, action, entityType) {
    if (action.entityType === entityType) {
        switch (action.type) {
            case CLEAR_ENTITY:
                return {
                    ...state,
                    data: action.data
                }
            case MAKE_REQUEST:
                switch (action.method) {
                    case 'GET':
                        return {
                            ...state,
                            error: null,
                            loading: true
                        }
                    default:
                        return {
                            ...state,
                            error: null,
                            saving: true
                        }
                }
            case REQUEST_SUCCESS:
                switch (action.method) {
                    case 'GET':
                        return {
                            ...state,
                            loading: false,
                            error: null,
                            data: action.data
                        }
                    case 'POST':
                        return {
                            ...state,
                            saving: false,
                            error: null,
                            data: {
                                ...state.data,
                                // this is tailored for generating client id and secret:
                                api_client_id: action.data.client_id,
                                client_secret: action.data.client_secret,
                            }
                        }
                    case 'PUT':
                        return {
                            ...state,
                            error: null,
                            saving: false,
                            data: {
                                ...state.data,
                                // this is tailored for re-generating client secret:
                                client_secret: action.data
                            }
                        }
                    default:
                        return state
                }
            case REQUEST_FAILED:
                switch (action.method) {
                    case 'GET':
                        return {
                            ...state,
                            loading: false,
                            error: {
                                message: action.error,
                                requestMethod: action.method
                            }
                        }
                    default:
                        return {
                            ...state,
                            saving: false,
                            error: {
                                message: action.error,
                                requestMethod: action.method
                            }
                        }
                }
            case UPDATE_STORE:
                return {
                    ...state,
                    data: {
                        ...state.data,
                        ...action.data
                    }
                }
            default:
                return state
        }
    }
    return state
}

const rootReducer = combineReducers({
    oidc: oidcReducer,
    user,
    org,
})

export default rootReducer