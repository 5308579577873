import React, {Component} from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";

import InfoField from '../forms/InfoField'

import {API_CLIENT_GENERATION, API_USER_CLIENT_GENERATION, ORG_TYPE, USER_TYPE} from "../../actions/entities";
import {entityActionRequest, entityRequest,} from "../../actions/entitiesActions";

import {Button, withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Saving from "../common/Saving";
import ConfirmDialog from "../../components/common/Dialog";
import Background from "../../theme/background.png";


const styles = theme => ({
    paper: {
        textAlign: 'center',
        marginBottom: 20
    },
    loader: {
        margin: '50px 48%'
    },
    form: {
        display: 'flex',
        width: '100%'
    },
    formRow: {
        padding: 10
    },
    spacedRow: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'space-between'
    },
    leftCol: {
        flexGrow: 1,
        marginRight: 20
    },
    subTitle: {
        marginTop: 20,
        marginLeft: 10
    },
    link: {
        color: theme.palette.secondary.main,
        '&:hover': {
            textDecoration: "none",
        },
    },
    icon: {
        margin: '10px 0 -5px',
        color: '#bbbbbb'
    },
    fromDevPortal: {
        '& #dashboard': {
            border: 'none',
            boxShadow: 'none',
            backgroundColor: '#002381',
            margin: '60px calc(50vw - 270px) auto',
            height: '210px',
            width: '523px',
        },
        '& form': {
            position: 'relative',
            top: '20%',
        },
        '& button': {
            backgroundColor: '#3abbff',
            fontColor: '#FFFFFF'
        }
    },
})

class Dashboard extends Component {

    initialState = {
        showConfirmation: false,
        name: '',
        id: null,
        logo: '',
        user_id: null,
        user_api_client_id: '',
        api_client_id: '',
        client_secret: ''
    }

    constructor(props) {
        super(props);
        this.state = {...this.initialState};

        this.loadOrg = this.loadOrg.bind(this);
        this.regenerate = this.regenerate.bind(this);
        this.closeConfirmation = this.closeConfirmation.bind(this);
        this.handleGenerate = this.handleGenerate.bind(this);
    }

    componentDidMount() {
        this.loadOrg()
    }

    loadOrg() {
        this.props.dispatch(entityRequest('GET', USER_TYPE, null, null, null, function () {
            this.props.dispatch(entityRequest('GET', ORG_TYPE, this.props.user.data.organization_id))
        }.bind(this)))

    }

    componentDidUpdate(prevProps, prevState) {
        const {org, user} = this.props
        const {data} = org
        const prevOrg = prevState;

        const user_api_client_id = user.data.api_client_ids? user.data.api_client_ids.find(v => v): null;
        const client_secret = user.data.client_secret || org.data.client_secret || '';

        if ((!prevOrg.id && data.id) ||
            (!prevOrg.api_client_id && data.api_client_id) ||
            (!prevOrg.user_api_client_id && user_api_client_id) ||
            (prevOrg.client_secret !== client_secret)) {
            // set initial form data
            let newState = ({
                name: data.name,
                id: data.id,
                user_id: user.data.id,
                logo: data.logo,
                user_api_client_id: user_api_client_id,
                api_client_id: data.api_client_id,
                client_secret: client_secret
            })
            this.setState(newState)
        }
    }

    regenerate(event) {
        event.preventDefault()
        if (this.state.api_client_id || this.state.user_api_client_id) {
            this.setState({showConfirmation: true})
        }
        else {
            this.handleGenerate(event)
        }
    }
    closeConfirmation() {
        this.setState({showConfirmation: false})
    }

    handleGenerate(event) {
        event.preventDefault();
        this.closeConfirmation()
        if (this.state.user_api_client_id)
        {
            this.props.dispatch(entityActionRequest(
                'PUT',
                USER_TYPE,
                this.state.user_id,
                null,
                API_USER_CLIENT_GENERATION))
        }
        else
        {
            const method = this.state.api_client_id ? 'PUT' : 'POST'
            this.props.dispatch(entityActionRequest(
                method,
                ORG_TYPE,
                this.state.id,
                null,
                API_CLIENT_GENERATION))

        }
    }

    render() {
        const org = this.state
        const {classes} = this.props
        let loader = '', error = '', saving= '', form = ''

        const fromDevPortal = sessionStorage.getItem('from_dev_portal');

        if (this.props.user.loading || this.props.org.loading) {
            loader = <CircularProgress color="secondary" className={classes.loader}/>
        } else if (this.props.org.error || !org.id) {
            error = <div>Failed to load the organization...</div>
        } else {
            if (this.props.org.saving) {
                saving = <Saving text="Generating..." />
            }

            const client_id =  org.user_api_client_id? org.user_api_client_id: org.api_client_id
            const btnText = client_id ? 'Generate new secret' : 'Generate API client and secret'

            form = (
                <form className={classes.form} onSubmit={this.regenerate}>
                    {this.state.showConfirmation &&
                    <ConfirmDialog title='Are you sure you want to regenerate your API client secret?'
                                   body='This will invalidate the previous secret, meaning that any application using it will stop working until you give it the new secret.'
                                   agreeText="Regenerate" cancelText="Cancel"
                                   closeCancel={this.closeConfirmation} closeAgree={this.handleGenerate} largerContent={true}/>
                    }
                    <div className={classes.leftCol}>
                        {client_id &&
                        <div className={classes.formRow}>
                            <InfoField displayName="API Client ID" value={client_id}/>
                        </div>
                        }
                        {org.client_secret &&
                        <div className={classes.formRow}>
                            <InfoField displayName="API Client secret" value={org.client_secret}/>
                        </div>
                        }

                        <Button type="submit" variant="contained" color="primary">
                            {btnText}
                        </Button>
                    </div>
                </form>
            )
        }

        return (
            <>
                <div className={[fromDevPortal ? classes.fromDevPortal : classes.fromOnboarding].join(' ')}>
                    <Paper className={classes.paper} id={"dashboard"}>
                            {loader}
                            {error}
                            {saving}
                            {form}
                    </Paper>
                </div>
            </>
        )
    }
}

function mapStateToProps(data) {
    const {org, user} = data
    return {
        user,
        org
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object,
    org: PropTypes.object,
}

export default connect(mapStateToProps)(withStyles(styles)(Dashboard))

