import React, {Component} from 'react'
import {Route, Switch} from 'react-router-dom'
import SecuredApp from './SecuredApp'
import CallbackPage from '../components/security/callback';

export default class AsyncApp extends Component {

    render() {
        return (
            <Switch>
                <Route exact path="/callback" component={CallbackPage} />
                <Route path="/" component={SecuredApp} />
            </Switch>
        )
    }

}
