import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {entityActionRequest, entityRequest} from "../../actions/entitiesActions";
import {ORG_TYPE, USER_TYPE, SEND_CONTACT_FORM} from "../../actions/entities";
import DragAndDropFile from "../forms/DragAndDropFile";
import {getClassName} from "../../utils/utils";
import ConfirmDialog from "../common/Dialog";


class SupportContact extends Component {
    
    initialState = {
        dropDownOpen: false,
        dropDownOpenPriority: false,
        error: false,
        showSuccess: false,
        formData: {
            subject: '',
            priority: '',
            title: '',
            content: '',
            file: {
                type: '',
                name: '',
                content: ''
            },
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.initialState,
            formData: {
                ...this.initialState.formData,
                subject: this.defaultSubject()
            }
        };

        this.loadOrg = this.loadOrg.bind(this);
        this.setFieldState = this.setFieldState.bind(this);
        this.handleTextfieldChange = this.handleTextfieldChange.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleDropdownPriority = this.toggleDropdownPriority.bind(this);
        this.changeSubject = this.changeSubject.bind(this);
        this.changePriority = this.changePriority.bind(this);
        this.setFile = this.setFile.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.validationError = this.validationError.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeMessage = this.closeMessage.bind(this);
    }

    defaultSubject() {
        const {location} = this.props
        const {pathname: path} = location
        if (path.includes('reportBug')){
            return "bug"
        }
        if (path.includes('featureRequest')){
            return "feature_request"
        }
        if (path.includes('askQuestion')){
            return "question"
        }
        if (path.includes('requestSource')){
            return "source"
        }
        return ''
    }

    componentDidMount() {
        this.loadOrg()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user.saving && !this.props.user.saving) {
            this.setState({showSuccess: true})
        }
    }

    loadOrg() {
        this.props.dispatch(entityRequest('GET', USER_TYPE, null, null, null, function () {
            this.props.dispatch(entityRequest('GET', ORG_TYPE, this.props.user.data.organization_id))
        }.bind(this)))
    }
    
    setFieldState(field, value) {
        this.setState({
            ...this.state,
            formData: {
                ...this.state.formData,
                [field]: value
            }
        })
    }

    handleTextfieldChange(event) {
        this.setFieldState(event.target.name, event.target.value);
    }

    toggleDropdown(event) {
        var newStatus = !this.state.dropDownOpen;
        var newState = {"dropDownOpen": newStatus};
        if (newStatus){
            newState["dropDownOpenPriority"] = false;
        }
        this.setState(newState);
    }

    toggleDropdownPriority(event) {
        var newStatus = !this.state.dropDownOpenPriority;
        var newState = {"dropDownOpenPriority": newStatus};
        if (newStatus){
            newState["dropDownOpen"] = false;
        }
        this.setState(newState);
    }
    
    changeSubject(event) {
        var newSubject = event.target.dataset.subject;
        this.setFieldState('subject', newSubject);
    }

    changePriority(event) {
        var newPriority = event.target.dataset.priority;
        this.setFieldState('priority', newPriority);
    }

    setFile(file) {
        this.setFieldState("file", file)
    }

    removeFile() {
        this.setFieldState('file', {
            type: '',
            name: '',
            content: ''
        })
    }
    
    validationError(field) {
        var message = "Please fill out the '" + field + "' field.";
        this.setState({error: message})
    }
    
    handleSubmit(event) {
        const formData = this.state.formData

        if (!formData.subject) {
            this.validationError("subject");
            return;
        }
        if (!formData.content) {
            this.validationError("content");
            return;
        }

        var formDataToSend = {
            ticket_topic: this.options[formData.subject],
            subject: formData.title,
            description: formData.content,
        };

        if (this.props.org.data.sla) {
            formDataToSend["priority"] = this.priority[formData.priority]
        }

        var fileContent = formData.file.content;
        if (fileContent) {
            var base64Pos = fileContent.indexOf(";base64,");
            fileContent = fileContent.slice(base64Pos + 8);
            formDataToSend['attachment'] = {
                ...formData.file,
                content: fileContent
            }
        }

        this.props.dispatch(entityActionRequest('POST', USER_TYPE, null, formDataToSend, SEND_CONTACT_FORM))
    }

    closeMessage() {
        this.setState({error: false, showSuccess: false})
    }

    options = {
        "bug": "Report a bug",
        "feature_request": "Submit a Feature request",
        "question": "Ask us a question",
        "source": "Ask to add a specific source",
    }

    priority = {
        "urgent": "Urgent",
        "high": "High",
        "normal": "Normal",
        "low": "Low",
    }

    render() {
        let optionDivs = [];
        let priorityDivs = [];

        const that = this;
        Object.keys(this.options).forEach(function(key){
            optionDivs.push(
                <div className="option"
                     onClick={that.changeSubject} data-subject={key}
                     key={key}>
                    {that.options[key]}
                </div>
            );
        });

        Object.keys(this.priority).forEach(function(key){
            priorityDivs.push(
                <div className="option"
                     onClick={that.changePriority} data-priority={key}
                     key={key}>
                    {that.priority[key]}
                </div>
            );
        });

        const {formData, dropDownOpen, dropDownOpenPriority, error, showSuccess} = this.state

        const dropDownClasses = getClassName("list-container", dropDownOpen && "open");
        const triggerButtonClasses = getClassName("trigger-button", dropDownOpen && "open");

        const dropDownClassesPriority = getClassName("list-container", dropDownOpenPriority && "open");
        const triggerButtonClassesPriority = getClassName("trigger-button", dropDownOpenPriority && "open");

        const sendButtonText = this.props.user.saving ? "Sending..." : "Send";



        let message = ''
        if (error) {
            message = <ConfirmDialog body={error}
                           cancelText="Got it"
                           closeCancel={this.closeMessage}/>
        }
        else if (showSuccess) {
            message = <ConfirmDialog title="Your request was sent successfully"
                                     body="Thank you for contacting Sixgill. We have received your message and our representatives will contact you as soon as possible."
                                     cancelText="Got it"
                                     closeCancel={this.closeMessage} />
        }


        return (
            <div className="SupportContactPage">
                {message}
                <div className="support-header">
                    <div className="breadcrumbs">
                        <Link className="breadcrumb" to="/support">Support</Link>
                        <span className="breadcrumb active">Contact</span>
                    </div>
                </div>
                <div className="support-content-wrapper">
                    <div className="support-content">
                        <div className="centered-form">
                            <h3>Contact Support</h3>

                            <label>Subject*</label>
                            <div className='header-dropdown' onClick={this.toggleDropdown}>
                                <button className={triggerButtonClasses}>{this.options[formData.subject] || 'Please choose one'}</button>
                                <div className={dropDownClasses}>
                                    {optionDivs}
                                </div>
                            </div>


                            {this.props.org.data.sla && [
                                <label key={1}>Priority</label>,
                                <div key={2} className='header-dropdown' onClick={this.toggleDropdownPriority}>
                                    <button className={triggerButtonClassesPriority}>{this.priority[formData.priority] || 'Please choose one'}</button>
                                    <div className={dropDownClassesPriority}>
                                        {priorityDivs}
                                    </div>
                                </div>
                            ]}


                            <label>Title</label>
                            <input type="text" value={formData.title} name="title" onChange={this.handleTextfieldChange}/>

                            <label>How can we help?*</label>
                            <textarea value={formData.content} name="content" onChange={this.handleTextfieldChange} />

                            <label>Attach files</label>
                            <DragAndDropFile setFile={this.setFile} removeFile={this.removeFile} file={formData.file} />

                        </div>
                        <div className="buttons">
                            <Link className="link-button" to="/support">Cancel</Link>
                            <button className="btn" onClick={this.handleSubmit}>{sendButtonText}</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(data) {
    const {org, user} = data
    return {
        org,
        user
    }
}

SupportContact.propTypes = {
    classes: PropTypes.object.isRequired,
    org: PropTypes.object,
    user: PropTypes.object,
}

export default connect(mapStateToProps)(SupportContact)