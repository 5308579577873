import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withStyles} from "@material-ui/core";


const styles = theme => ({
    wrapper: {

    }
})


class InfoField extends Component {

    render() {
        const { classes, displayName, value } = this.props

        return (
            <div className={classes.wrapper}>
                <label>{displayName}:</label>&nbsp;
                {value}
            </div>
        )
    }
}

InfoField.propTypes = {
    classes: PropTypes.object.isRequired,
    displayName: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default withStyles(styles)(InfoField)