import { createUserManager } from 'redux-oidc';

const currentUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`

const userManagerConfig = {
  client_id: 'onboarding-portal',
  redirect_uri: currentUrl + '/#/callback',
  post_logout_redirect_uri: currentUrl,
  response_type: 'code',
  scope: 'openid profile manage:own_api_client',
  authority: process.env.REACT_APP_KEYCLOAK_URL,
  silent_redirect_uri: currentUrl + '/silent_renew.html',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
