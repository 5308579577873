import React, {Component} from 'react'
import JotformEmbed from 'react-jotform-embed';



class Mainpage extends Component {

    render() {
        return (
            <div className="Main-page">
                <JotformEmbed src="https://form.jotform.com/210834096051046" />
            </div>
        )
    }
}


export default Mainpage


