import React from "react";
import {NavLink} from "react-router-dom";

/**
 * Gets an array of strings and attaches them to a single class
 * name. An empty string or falsy value will not be attached.
 * @param  {...string | boolean} classes The names of the classes we want to attach to each other.
 * @returns {string} The unified string.
 */
export function getClassName(...classes) {
    return classes.filter(Boolean).join(' ')
}

export const LinkWithRouter = React.forwardRef((props, ref) => <NavLink exact innerRef={ref} {...props} />);

export function prettyDateTime(dateStr) {
    const date = new Date(dateStr)
    return date.toLocaleString()
}