import React from "react";
import userManager from "../../utils/userManager";
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Background from "../../theme/background.png";

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        //background: `url(${Background})`
    },
    fromOnboarding: {
        background: `url(${Background})`
    },
    fromDevPortal: {
      background: false,

      '& #login': {
          border: 'none',
          boxShadow: 'none',
          backgroundColor: '#002381',
          margin: '60px calc(50vw - 270px) auto',
          height: '210px',
          width: '523px',
      },
    },
    messageWrapper: {
        width: 500,
        textAlign: 'center',
        margin: '60px calc(50vw - 270px) auto',
        padding: 50
    },
})

class LoginPage extends React.Component {
    constructor(props) {
        super(props)

        this.onLoginButtonClick = this.onLoginButtonClick.bind(this)
    }

    onLoginButtonClick(event) {
        event.preventDefault();
        sessionStorage.setItem('after_login_redirect', JSON.stringify(this.props.location));
        userManager.signinRedirect();
    }

    render() {
        const {classes} = this.props

        const fromDevPortal = sessionStorage.getItem('from_dev_portal');
        var portalStr = fromDevPortal? "Developer" : "Onboarding";
        return (
            <div className={[classes.root, fromDevPortal ? classes.fromDevPortal : classes.fromOnboarding].join(' ')}>
                <CssBaseline/>
                <Paper className={classes.messageWrapper} id={'login'}>
                    <Typography variant="h5" gutterBottom={true}>
                        Welcome to Cybersixgill's {portalStr} Portal!
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subHeading} gutterBottom={true}>
                        Please log in to continue
                    </Typography>
                    <Button onClick={this.onLoginButtonClick} variant="contained" color="secondary">Login</Button>
                </Paper>
            </div>
        )
    }
}

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
    location: PropTypes.object
}

export default withStyles(styles)(LoginPage)
