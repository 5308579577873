import fetch from 'cross-fetch'
import { store } from '../containers/Root'
import {
    USER_TYPE,
    ORG_TYPE,
    API_CLIENT_GENERATION,
    API_USER_CLIENT_GENERATION,
    SEND_CONTACT_FORM,
} from './entities'


export const CLEAR_ENTITY = 'CLEAR_ENTITY'
export const MAKE_REQUEST = 'MAKE_REQUEST'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_FAILED = 'REQUEST_FAILED'
export const UPDATE_STORE = 'UPDATE_STORE'

const user_data_url = process.env.REACT_APP_USER_DATA_API_URL

export function clearEntity(entityType, data) {
    return {
        type: CLEAR_ENTITY,
        entityType,
        data
    }
}

function makeRequest(method, entityType, id, data) {
    return {
        type: MAKE_REQUEST,
        method,
        entityType,
        id,
        data
    }
}

export function requestSuccess(method, entityType, data) {
    return {
        type: REQUEST_SUCCESS,
        method,
        entityType,
        data
    }
}

function requestFailed(method, entityType, error) {
    return {
        type: REQUEST_FAILED,
        method,
        entityType,
        error
    }
}

function updateStore(entityType, data) {
    return {
        type: UPDATE_STORE,
        entityType,
        data
    }
}

function getEntityEndpoint(method, entityType, id) {
    switch (entityType) {
        case USER_TYPE:
            return `${user_data_url}/user`
        case ORG_TYPE:
            return `${user_data_url}/organization/${id}`

        default:
            return null
    }
}

function getEntityActionEndpoint(method, entityActionType, id) {
    switch (entityActionType) {

        case API_USER_CLIENT_GENERATION:
            return `${user_data_url}/user/${id}/api`

        case API_CLIENT_GENERATION:
            return `${user_data_url}/organization/${id}/api`

        case SEND_CONTACT_FORM:
            return `${user_data_url}/ticket`

        default:
            return null
    }
}

function addParamsToUrl(url, params) {
    url = new URL(url)
    let newParams = new URLSearchParams(url.search.slice())
    for (let [key, value] of Object.entries(params)) {
      newParams.set(key, value)
    }
    return `${url.origin}${url.pathname}?${newParams.toString()}`

}

function request(method, entityType, id, data, updateStoreAction, url, params, callback) {
    return async dispatch => {
        dispatch(makeRequest(method, entityType, id, data))

        try {
            if (!url) {
                url = getEntityEndpoint(method, entityType, id)
            }

            if (params) {
                url = addParamsToUrl(url, params)
            }

            const token = store.getState().oidc.user.access_token;
            const headers = {
                'Accept': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            }

            const options = {
                method,
                headers,
                mode: 'cors'
            };
            if (data) {
                options['body'] = JSON.stringify(data)
            }

            let response = await fetch(url, options)

            if (!response.ok) {
                response = await response.json()
                throw Error(response.message || response.statusText)
            }

            // also setting data to store, since update response is not necessarily the update data,
            // or may not contain empty fields:
            if (updateStoreAction) {
                dispatch(updateStoreAction)
            }

            response = await response.json();
            await dispatch(requestSuccess(method, entityType, response))
            if (callback) {
                await callback()
            }
        } catch (err) {
            dispatch(requestFailed(method, entityType, err))
        }
    }
}

export function entityRequest(method, entityType, id, data, params, callback) {
    var updateStoreAction = null
    if (method === 'PUT' || method === 'POST') {
        updateStoreAction = updateStore(entityType, data)
    }
    return request(method, entityType, id, data, updateStoreAction, null, params, callback)
}

export function entityActionRequest(method, entityType, id, data, entityAction, updateStoreData) {
    var url = getEntityActionEndpoint(method, entityAction, id)

    var updateStoreAction = null
    if (updateStoreData) {
        updateStoreAction = updateStore(entityType, updateStoreData)
    }

    return request(method, entityType, id, data, updateStoreAction, url)
}