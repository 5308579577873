import React, {Component} from "react";
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import ConfirmDialog from "../common/Dialog";
import {getClassName} from "../../utils/utils";

const styles = theme => ({
    DragAndDropFiles: {

    },
    highlight: {

    }
})

class DragAndDropFile extends Component {

    initialState = {
        dragDropHighlight: false,
        error: false
    }

    constructor(props) {
        super(props)
        this.state = {...this.initialState}

        this.dragEnter = this.dragEnter.bind(this)
        this.dragOver = this.dragOver.bind(this)
        this.dragLeave = this.dragLeave.bind(this)
        this.drop = this.drop.bind(this)
        this._dragDropEventCallback = this._dragDropEventCallback.bind(this)
        this.handleDrop = this.handleDrop.bind(this)
        this.handleFileSelect = this.handleFileSelect.bind(this)
        this.handleFile = this.handleFile.bind(this)
        this.closeMessage = this.closeMessage.bind(this)
    }


    dragEnter(e) {
        this._dragDropEventCallback(e, true);
    }
    dragOver(e) {
        this._dragDropEventCallback(e, true);
    }
    dragLeave(e) {
        this._dragDropEventCallback(e, false);
    }
    drop(e) {
        this._dragDropEventCallback(e, false);
        this.handleDrop(e);
    }

    _dragDropEventCallback(e, setHighlight) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({dragDropHighlight: setHighlight});
    }

    handleDrop(e) {
        const dt = e.dataTransfer
        const files = dt.files
        const file = files[0]
        if (file.type.includes("image/")) {
            this.handleFile(file)
        }
        else {
            this.setState({error: "Only images allowed."})
        }
    }


    handleFileSelect(e) {
        this.handleFile(this.refs.fileInput.files[0]);
        e.target.value = null;
    }

    handleFile(file) {
        if (file) {
            const that = this;
            const reader = new FileReader();

            reader.addEventListener("load", function () {
                that.props.setFile({
                    "type": file.type,
                    "name": file.name,
                    "content": reader.result
                });
            }, false);

            reader.readAsDataURL(file);
        }
    }

    closeMessage() {
        this.setState({error: false})
    }

    render() {

        const {classes} = this.props

        let filePreview = '';
        if (this.props.file.content) {
            filePreview = (
                <div>
                    <img src={this.props.file.content} />
                    <i className="fal fa-times" onClick={this.props.removeFile} />
                </div>
            )
        }

        let classNames = getClassName(classes.DragAndDropFiles, this.state.dragDropHighlight && classes.highlight)

        let message = ''
        if (this.state.error) {
            message = <ConfirmDialog body={this.state.error}
                           cancelText="Got it"
                           closeCancel={this.closeMessage}/>
        }

        return (
            <div id="drop-area" className={classNames}
                                 onDragEnter={this.dragEnter}
                                 onDragOver={this.dragOver}
                                 onDragLeave={this.dragLeave}
                                 onDrop={this.drop}>
                <div>Drag & Drop file</div>
                <div>(image file only)</div>
                <input type="file" id="fileElem" ref="fileInput" accept="image/*" onChange={this.handleFileSelect}/>
                <label className="button" htmlFor="fileElem">Or browse for a file</label>
                <div id="gallery">
                    {filePreview}
                </div>
                {message}
            </div>
        );
    }
}

DragAndDropFile.propTypes = {
    classes: PropTypes.object.isRequired,
    file: PropTypes.object,
}

export default (withStyles(styles)(DragAndDropFile))