import React, {Component} from "react";
import {Link} from "react-router-dom";
import SmallLogo from '../../theme/sixgill-logo-small.png';

export default class SupportMain extends Component {
    render() {
        const {match} = this.props
        const base_url_for_links = match.url.replace(/\/$/, "")
        return (
            <div className="SupportMainPage">
                <div className="support-full-header">
                    <div className="breadcrumbs">
                        <span className="breadcrumb active">Support</span>
                    </div>
                    <h1>How can we help you?</h1>
                </div>
                <div className="support-content full-width">
                    <div className="support-main-page-links">
                        <div className="support-main-section">
                            <div className="support-main-section-header documentation">
                                Documentation
                            </div>
                            <a href={`${process.env.PUBLIC_URL}/documents/CyberSixgill API User Guide.pdf`} target="_blank">CyberSixgill's API V2 user guide</a>
                            <a href={`${process.env.PUBLIC_URL}/documents/Cybersixgill API V3 User Guide.pdf`} target="_blank">CyberSixgill's API V3 user guide</a>
                            <a href={`${process.env.REACT_APP_CYBERSIXGILL_URL}/api-restrictions/`} target="_blank">Restrictions on Scope and Use of the API</a>
                        </div>
                        <div className="support-main-section">
                            <div className="support-main-section-header contact-us">
                                Contact Support
                            </div>
                            <Link to={`${base_url_for_links}/contact/reportBug`}>Report an error/bug</Link>
                            <Link to={`${base_url_for_links}/contact/askQuestion`}>Ask Question</Link>
                            <Link to={`${base_url_for_links}/contact/featureRequest`}>Request a feature</Link>
                            <Link to={`${base_url_for_links}/contact/requestSource`}>Ask to add a source</Link>
                        </div>
                    </div>
                    <div className="support-footer">
                        <img src={SmallLogo} alt="sixgill"/>
                    </div>
                </div>

            </div>
        )
    }
}