import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    wrapper: {
        position: 'fixed',
        top: '100px',
        right: '50px'
    },
    message: {
        color: 'white',
        padding: '5px 30px',
        background: theme.palette.primary.main
    }
})


class Saving extends Component {

    render() {
        const { classes, text } = this.props

        return (
            <Card className={classes.wrapper}>
                <Typography className={classes.message}>{text}</Typography>
            </Card>
        )
    }
}

Saving.propTypes = {
    text: PropTypes.string.isRequired
}

export default withStyles(styles)(Saving)