import React, {Component} from 'react'
import {Provider} from 'react-redux'
import {HashRouter as Router} from 'react-router-dom'
import configureStore from '../store/configureStore'
import AsyncApp from './AsyncApp'
import {loadUser, OidcProvider} from "redux-oidc";
import userManager from "../utils/userManager";
import {ThemeProvider} from "@material-ui/styles";
import theme from '../theme/theme';


export const store = configureStore()
loadUser(store, userManager);

// <Provider> binds react to redux
export default class Root extends Component {
    render() {
        return (
            <Provider store={store} >
                <OidcProvider store={store} userManager={userManager}>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <AsyncApp/>
                        </Router>
                    </ThemeProvider>
                </OidcProvider>
            </Provider>
        )
    }
}
