import React, {Component} from 'react'
import {connect} from "react-redux";
import {Route, Switch} from "react-router";
import PropTypes from 'prop-types'

import userManager from "../utils/userManager";
import LoginPage from "../components/security/loginPage"
import AccessDenied from "../components/security/accessDenied"
import Mainpage from "../components/mainpage/Mainpage";
import Dashboard from "../components/dashboard/Dashboard";
import {LinkWithRouter} from "../utils/utils"

import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import {AppBar} from "@material-ui/core";
import Link from '@material-ui/core/Link';
import Toolbar from '@material-ui/core/Toolbar';
import Background from '../theme/background.png';
import Support from "../components/support/Support";


const styles = theme => ({
    root: {
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        //background: `url(${Background})`
    },
    fromOnboarding: {
        background: `url(${Background})`
    },
    fromDevPortal: {
      background: false,
    },
    toolbar: {
        backgroundColor: 'rgb(23, 26, 43)',
        color: 'rgb(255, 255, 255)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.3)'
    },
    siteNameWrp: {
        flexGrow: 1,
    },
    link: {
        '&:hover': {
            textDecoration: "none",
        },
    },
    siteName: {
        color: 'white',
    },
    userInfo: {
        display: 'flex',
        alignItems: 'center'
    },
    hello: {
        marginRight: 20
    },
    main: {
        flexGrow: 1,
        overflow: 'auto',
        padding: 20,
    },
    marginTop: {
        marginTop: 64
    }
})

class SecuredApp extends Component {

    logout(event) {
        event.preventDefault()
        userManager.signoutRedirect() // signs out of identity provider
        userManager.removeUser() // removes the user data from sessionStorage
    }

    render() {
        const {classes, user} = this.props;

        var firstTimeLogin = sessionStorage.getItem('first_time_login');
        const query = new URLSearchParams(this.props.location.search);
        const fromDevPortal = query.get('from_dev_portal') || sessionStorage.getItem('from_dev_portal');

        if (fromDevPortal) {
            sessionStorage.setItem('from_dev_portal', fromDevPortal);
        }

        if (!user && firstTimeLogin == null) {
            sessionStorage.setItem('first_time_login', true);
            sessionStorage.setItem('after_login_redirect', JSON.stringify(this.props.location));
            userManager.signinRedirect();
        }

        if (!user || user.expired) {
            return <LoginPage location={this.props.location} />
        } else {
            const userName = user.profile.preferred_username
            const apiUser = typeof user.profile.roles !== "undefined" && user.profile.roles.length > 0

            return (
                <div className={[classes.root, fromDevPortal ? classes.fromDevPortal : classes.fromOnboarding].join(' ')}>
                    <CssBaseline/>
                    {
                        !fromDevPortal &&
                        <AppBar position="absolute">
                            <Toolbar className={classes.toolbar}>
                                <Typography component="h1" variant="h6" color="inherit" noWrap
                                            className={classes.siteNameWrp}>
                                    <Link to="/" component={LinkWithRouter} className={classes.link}>
                                        <div className={classes.siteName}> Cybersixgill Onboarding</div>
                                    </Link>
                                </Typography>
                                <div className={classes.userInfo}>
                                    <div className={classes.hello}>
                                        Hello, {userName}!
                                    </div>
                                    <Button
                                        onClick={this.logout}
                                        color="secondary"
                                        variant="contained">
                                        Logout
                                    </Button>
                                </div>
                            </Toolbar>
                        </AppBar>
                    }
                    <div className={[classes.main, fromDevPortal ? "" : classes.marginTop].join(' ')}>
                        <Switch>
                            <Route exact path="/" component={Mainpage}/>
                            {apiUser && <Route exact path="/generate" component={Dashboard}/>}
                            {apiUser && <Route path="/support" component={Support}/>}
                        </Switch>
                    </div>
                </div>
            );
        }
    }
}

SecuredApp.propTypes = {
    classes: PropTypes.object.isRequired,
}

function mapStateToProps(data) {
    return {
        user: data.oidc.user
    }
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SecuredApp))
