import {createMuiTheme} from '@material-ui/core/styles';

const titlesFont = [
    'Rajdhani',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
].join(',')
const bodyFont = [
    'Heebo',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif'
].join(',')

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#0e73b7',
        },
        secondary: {
            main: '#43acff',
        },
        background: {
            default: "transparent"
        }
    },
    typography: {
        fontFamily: bodyFont,
    },
    overrides: {
        MuiPaper: {
            elevation1: {
                border: '1px solid rgba(85, 207, 255, 0.4)',
                background: 'linear-gradient(to bottom, rgba(2, 40, 73, 0.5), rgba(25, 39, 57, 0))',
                boxShadow: '6px 0 4px 0 rgba(0, 0, 0, 0.5)',
                padding: '20px'
            }
        },
        MuiCard: {
            root: {
                border: 'none',
                background: 'transparent',
                boxShadow: 'none',
                padding: 0
            }
        },
        MuiTypography: {
            h1: {
                fontFamily: titlesFont,
                fontWeight: 600
            },
            h2: {
                fontFamily: titlesFont,
                fontWeight: 600
            },
            h3: {
                fontFamily: titlesFont,
                fontWeight: 600
            },
            h4: {
                fontFamily: titlesFont,
                fontWeight: 600
            },
            h5: {
                fontFamily: titlesFont,
                fontWeight: 600
            },
            h6: {
                fontFamily: titlesFont,
                fontWeight: 600
            },
        },
        MuiDialog: {
            paper: {
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                color: '#d9e3e8',
                backdropFilter: 'blur(100px)',
                border: '1px solid #2196f3',
                backgroundImage: 'linear-gradient(to bottom, rgba(33, 150, 243, 0.34), rgba(0, 152, 255, 0.1))',
            }
        }
    }
});

export default theme;
