import React from "react";
import userManager from "../../utils/userManager";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import {Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import Background from "../../theme/background.png";


const styles = theme => ({
    root: {
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        background: `url(${Background})`
    },
    messageWrapper: {
        width: 500,
        textAlign: 'center',
        margin: '60px calc(50vw - 250px) auto',
        padding: 50
    },
})

class AccessDenied extends React.Component {
    onLoginButtonClick(event) {
        event.preventDefault();
        userManager.signinRedirect();
    }

    render() {
        const {classes} = this.props

        return (
            <div className={classes.root}>
                <CssBaseline/>
                <Paper className={classes.messageWrapper}>
                    <Typography variant="h5" gutterBottom={20}>
                        Access Denied
                    </Typography>
                    <Typography variant="subtitle1" className={classes.subHeading} gutterBottom={30}>
                        Sorry, you do not have permissions to view the onboarding portal.
                    </Typography>
                    <Button onClick={this.props.logout} variant="contained" color="secondary">Logout</Button>
                </Paper>
            </div>
        )
    }
}

AccessDenied.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AccessDenied)